import React from "react";
import styled from "styled-components";
import { sectionHeading } from "../tokens/typography";
import { fancyLinkStyles } from "../tokens/utils";
import { transparentize } from "polished";
import { focusState } from "../tokens/a11y";

const RichText = ({ children, className }) => (
  <Wrapper className={className}>{children}</Wrapper>
);

export default RichText;

const Wrapper = styled.div`
  > h2 {
    ${sectionHeading};

    display: inline-block;
  }

  a {
    ${fancyLinkStyles(
      ({ theme }) => transparentize(0.6, theme.palette.lightBlue),
      ({ theme }) => transparentize(0.2, theme.palette.darkerLightBlue),
      "0.15em"
    )}

    text-decoration: none;

    &:focus {
      ${focusState(({ theme }) => theme.palette.darkerLightBlue)}}
    }
  }

  blockquote {
    margin-top: 1em;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    padding-top: 1em;
    padding-right: 1.5em;
    padding-bottom: 1em;
    padding-left: 1.5em;
    border-left: 0.25rem solid ${({ theme }) => theme.palette.white};
    background-color: ${({ theme }) =>
      transparentize(0.9, theme.palette.white)};

    > p {
      &:first-child {
        margin-top: 0;
        &::before {
          content: open-quote;
        }
      }

      &:last-child {
        &::after {
          content: close-quote;
        }
      }
    }
  }

  hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-color: ${({ theme }) => transparentize(0.5, theme.palette.white)};
  }
`;
