import styled from "styled-components";
import { pageTitle, siteTitle } from "../tokens/typography";

export const SiteTitle = styled.h1`
  ${siteTitle};
`;

export const PageTitle = styled.h1`
  ${pageTitle};

  margin-top: 0;
`;
